<template>
  <el-dialog title="登录验证" :visible.sync="dialogVisible" append-to-body width="500px">
    <div>
      <el-alert
        :title="dialogMsg"
        description="[验证密钥会由系统发送至钉钉，请注意查收。如有问题请联系管理员。]"
        type="error"
        show-icon
        :closable="false"
      />
      <el-form
        :model="loginForm"
        label-position="right"
        label-width="80px"
        style="margin-top: 10px"
        ref="loginValidateForm"
        :rules="formRules"
      >
        <el-form-item label="验证密钥" prop="verifyCode">
          <el-input v-model="loginForm.verifyCode" placeholder="请输入验证密钥" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handlerSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        loginForm: {
          verifyCode: '',
        },
        formRules: {
          verifyCode: [
            {
              required: true,
              message: '请输入验证密钥',
            },
          ],
        },
      };
    },
    props: {
      api: {
        type: Function,
      },
      mode: {
        type: String,
        default: 'ip',
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:visible'],
    computed: {
      dialogVisible: {
        get() {
          return this.visible;
        },
        set(visible) {
          this.$emit('update:visible', visible);
        },
      },
      dialogMsg() {
        const type = this.mode;
        if (type === 'ip') {
          return '登录IP异常！请输入验证密钥以保证正常登录！';
        } else if (type === 'password') {
          return '账户存在泄露风险！请验证密钥以确保账户安全！';
        }
        return '';
      },
    },
    methods: {
      closeDialog() {
        this.dialogVisible = false;
      },
      async handlerSubmit() {
        try {
          await this.$refs.loginValidateForm.validate();
          await this.api(this.loginForm?.verifyCode);
        } catch (e) {
          console.error('validate login error', e);
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
