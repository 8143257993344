<template>
  <div class="login-container">
    <div class="login_header"></div>
    <vue-particles
      color="#fff"
      :particlesNumber="60"
      :moveSpeed="1.5"
      :lineOpacity="0.5"
      class="bg"
    ></vue-particles>
    <div class="loginBox">
      <el-form
        class="login-form"
        label-position="left"
        :model="loginForm"
        :rules="loginRules"
        ref="loginForm"
      >
        <h3 class="login_title">{{ title }}</h3>
        <h4 class="login_futitle">{{ platform }}</h4>
        <el-form-item prop="username">
          <span class="svg-container svg-container_login">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            name="username"
            type="text"
            v-model="loginForm.username"
            placeholder="username"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            name="password"
            :type="pwdType"
            @keyup.enter.native="login('loginForm')"
            v-model="loginForm.password"
            placeholder="password"
          ></el-input>
          <span class="show-pwd" @click="showPwd">
            <svg-icon icon-class="eye" />
          </span>
        </el-form-item>
        <el-form-item>
          <el-button
            class="login_button"
            :loading="loading"
            @click.native.prevent="login('loginForm')"
          >
            Sign in
          </el-button>
        </el-form-item>
        <p style="font-size:12px;line-height:30px;color:#fff;float:left;">
          Tips : 尚未注册密码？
        </p>
        <div class="tips">{{ company }}</div>
      </el-form>
    </div>
    <ValidateLoginDialog
      :visible.sync="validateLoginDialogInfo.visible"
      :api="submitValidateLogin"
      :mode="validateLoginDialogInfo.mode"
    />
  </div>
</template>
<script>
  import ValidateLoginDialog from './components/ValidateLoginDialog.vue';

  export default {
    components: {
      ValidateLoginDialog,
    },
    data() {
      return {
        title: 'Management Console',
        platform: '',
        company: '',
        loading: false,
        pwdType: 'password',
        loginForm: {
          username: '',
          password: '',
        },
        validateLoginDialogInfo: {
          visible: false,
          mode: 'ip',
        },
      };
    },
    computed: {
      loginRules() {
        const validatePass = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入密码'));
          } else {
            if (this.loginForm.checkPass !== '') {
              this.$refs.loginForm.validateField('checkPass');
            }
            callback();
          }
        };
        const validateName = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入用户名'));
          } else {
            callback();
          }
        };
        return {
          password: [{ validator: validatePass, trigger: 'blur' }],
          username: [{ validator: validateName, trigger: 'blur' }],
        };
      },
    },
    methods: {
      showPwd() {
        if (this.pwdType === 'password') {
          this.pwdType = '';
        } else {
          this.pwdType = 'password';
        }
      },
      async submitValidateLogin(verifyCode) {
        this.login('loginForm', verifyCode);
      },
      async login(formName, verifyCode) {
        this.$refs[formName]?.validate((valid) => {
          if (valid) {
            const userInfo = {
              username: this.loginForm.username,
              password: this.loginForm.password,
            };
            if (verifyCode) {
              userInfo.verifyCode = verifyCode;
            }
            this.$store
              .dispatch('user/login', userInfo)
              .then(
                (res) => {
                  this.loading = false;
                  if (res.code === 200) {
                    sessionStorage.setItem('userName', this.loginForm.username);
                    sessionStorage.setItem('password', this.loginForm.password);
                    sessionStorage.setItem('imgUrl', res.logo);
                    sessionStorage.setItem('roleName', res.result?.roleName);
                    return this.$store.dispatch('app/getMenuList', true);
                  } else if (res.code === 1022) {
                    sessionStorage.setItem('userName', this.loginForm.username);
                    this.$router.replace('/');
                  } else if ([1025, 1028].includes(res.code)) {
                    this.validateLoginDialogInfo.mode = res.code === 1028 ? 'password' : 'ip';
                    this.validateLoginDialogInfo.visible = true;
                  } else if ([1026, 1027].includes(res.code)) {
                    this.$message.error(res.message);
                  }
                },
                (error) => {
                  this.loading = false;
                  this.$message.error(error);
                  return Promise.reject(error);
                }
              )
              .then(
                (res) => {
                  this.$router.push(res[0]?.index);
                },
                (error) => {
                  console.error(error);
                }
              );
          } else {
            this.$message.error('请输入账号或密码');
            return false;
          }
        });
      },
    },
  };
</script>

<style rel="stylesheet/scss" lang="scss">
  $bg: #2d3a4b;
  $light_gray: #eee;
  /* reset element-ui css */
  .login-container {
    .el-input {
      display: inline-block;
      height: 47px;
      width: 90%;
      background: rgba(255, 255, 255, 0.1) !important;

      input {
        background: transparent !important;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: $light_gray !important;
        height: 47px;

        &:-webkit-autofill {
          box-shadow: 0px 0px 0px 1000px #499de7 inset;
          -webkit-text-fill-color: #fff !important;
        }

        &:-webkit-input-placeholder {
          color: #fff !important;
        }
      }
    }

    .el-form-item {
      border-radius: 4px;
      border: 1px solid rgba(33, 150, 243, 0.1) !important;
      background: rgba(255, 255, 255, 0.1) !important;
      color: #eee !important;

      .el-form-item__error {
        color: #f56c6c !important;
        font-size: 12px !important;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        font-weight: bold;
        top: 100%;
        left: 0;
      }
    }
  }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
  $bg: #2196f3;
  $dark_gray: #889aa4;
  $light_gray: #eee;
  .bg {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  .login-container {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: $bg;
    display: table;

    .login_header {
      height: 40px;
      background-color: rgba(68, 138, 255, 0.5);
    }

    .loginBox {
      max-width: 500px;
      height: 400px;
      padding: 10px 0 15px 0;
      margin: 40px auto;
      background-color: rgba(25, 118, 210, 0.5);
      box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      .login-form {
        width: 400px;
        padding: 10px 0 15px 0;
        margin: 20px auto;
      }
    }

    .login_button {
      width: 100%;
      background-color: #ff9800;
      color: #eee;

      &:hover {
        box-shadow: -2px -2px 10px 6px rgba(0, 0, 0, 0.1);
      }
    }

    .tips {
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;
      clear: both;
      text-align: center;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .svg-container {
      color: $light_gray;
      vertical-align: middle;
      padding-left: 10px;
      width: 30px;
      display: inline-block;

      &_login {
        font-size: 20px;
      }
    }

    .login_title {
      font-size: 26px;
      font-weight: 400;
      color: $light_gray;
      margin: 0px auto 10px auto;
      text-align: center;
      font-weight: bold;
    }

    .login_futitle {
      font-size: 16px;
      font-weight: 200;
      color: #ffeb3b;
      margin: 0px auto 20px auto;
      text-align: center;
      font-weight: bold;
    }

    .show-pwd {
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 16px;
      color: $dark_gray;
      cursor: pointer;
      user-select: none;
    }

    .fontcontainer {
      color: #889aa4;
      padding-left: 10px;
    }
  }
</style>
